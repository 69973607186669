import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import notify from "./plugins/notify";
import notifyUtil from "@/util/notify";
import store from "@/store";

Vue.config.productionTip = false;

Vue.use(VueCompositionAPI);
Vue.use(DashboardPlugin);
Vue.use(notify);
Vue.use(store);

// const apiHost = "https://api.vuleka.demo.deepsinkpartners.tech";
// const apiHost = "http://127.0.0.1";

const axiosInstance = axios.create({
  // baseURL: `${apiHost}/api`,
  timeout: 60000,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      localStorage.clear();
      notifyUtil.warning("You have been logged out. Please login.");
      setTimeout(() => {
        window.location = "/login";
      }, 500);
    } else {
      throw error;
    }
  }
);

Vue.prototype.$http = axiosInstance;

Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: "AIzaSyBgxNLN7nETPX9jILFVO3nORHTYl4cErHg",
  // region: "za",
  country: ["za"],
  // components: "country:za",
  // bounds: defaultBounds,
  // strictBounds: true,
  // version: "...", // Optional
  language: "en",
  installComponents: true, // Optional (default: true) - false, if you want to locally install components
});

Vue.mixin({
  data: () => ({
    // apiHost,
    globalMessages: {
      unexpectedError:
        "An unexpected error ocurred. Kindly retry the action, or contact support if the issue persists.",
    },
    bannerStatuses: [
      {
        key: "active",
        value: "Active",
      },
      {
        key: "inactive",
        value: "Inactive",
      },
      {
        key: "pending_review",
        value: "Pending Review",
      },
    ],
    bannerTypes: [
      {
        key: "static",
        value: "Static",
      },
      {
        key: "sale",
        value: "Sale",
      },
      {
        key: "hamper",
        value: "Hamper",
      },
    ],
    deliveryStatuses: [
      {
        key: "draft",
        value: "Draft",
      },
      {
        key: "review_and_assign_driver",
        value: "Review and Assign Driver",
      },
      {
        key: "awaiting_delivery",
        value: "Awaiting Delivery",
      },
      {
        key: "awaiting_payment",
        value: "Awaiting Payment",
      },
      {
        key: "in_transit",
        value: "In transit",
      },
      {
        key: "delivered",
        value: "Delivered",
      },
      {
        key: "delivered_unpaid",
        value: "Delivered Unpaid",
      },
      {
        key: "delivery_rescheduled",
        value: "Delivery Rescheduled",
      },
      {
        key: "cancelled",
        value: "Cancelled",
      },
      {
        key: "cancelled_process_refund_and_restore_stock",
        value: "Cancelled: Process Refund and Restore Stock",
      },
      {
        key: "cancelled_restore_stock",
        value: "Cancelled: Restore Stock",
      },
      {
        key: "packaging",
        value: "Packaging",
      },
      {
        key: "undelivered",
        value: "Undelivered",
      },
    ],
    driverStatuses: [
      {
        key: "active",
        value: "Active",
      },
      {
        key: "inactive",
        value: "Inactive",
      },
      {
        key: "pending_approval",
        value: "Pending Approval",
      },
    ],
    orderStatuses: [
      {
        key: "cart",
        value: "Cart",
      },
      {
        key: "new",
        value: "New",
      },
      {
        key: "packaging",
        value: "Packaging",
      },
      {
        key: "alternatives_and_replacements",
        value: "Alternatives And Replacements",
      },
      {
        key: "awaiting_replacement_confirmation",
        value: "Awaiting Replacement Confirmation",
      },
      {
        key: "awaiting_payment",
        value: "Awaiting Payment",
      },
      {
        key: "review_and_assign_driver",
        value: "Review and Assign Driver",
      },
      {
        key: "awaiting_delivery",
        value: "Awaiting Delivery",
      },
      {
        key: "in_transit",
        value: "In Transit",
      },
      {
        key: "delivered",
        value: "Delivered",
      },
      {
        key: "delivered_unpaid",
        value: "Delivered Unpaid",
      },
      {
        key: "delivery_rescheduled",
        value: "Delivery Rescheduled",
      },
      {
        key: "cancelled",
        value: "Cancelled",
      },
      {
        key: "cancelled_process_refund_and_restore_stock",
        value: "Cancelled: Process Refund and Restore Stock",
      },
      {
        key: "cancelled_restore_stock",
        value: "Cancelled: Restore Stock",
      },
      {
        key: "undelivered",
        value: "Undelivered",
      },
    ],
    mutationOrderStatuses: [
      {
        key: "new",
        value: "New",
      },
      {
        key: "packaging",
        value: "Packaging",
      },
      {
        key: "alternatives_and_replacements",
        value: "Alternatives And Replacements",
      },
      {
        key: "awaiting_replacement_confirmation",
        value: "Awaiting Replacement Confirmation",
      },
      {
        key: "awaiting_payment",
        value: "Awaiting Payment",
      },
      {
        key: "review_and_assign_driver",
        value: "Review and Assign Driver",
      },
      {
        key: "cancelled",
        value: "Cancelled",
      },
      {
        key: "cancelled_process_refund_and_restore_stock",
        value: "Cancelled: Process Refund and Restore Stock",
      },
      {
        key: "cancelled_restore_stock",
        value: "Cancelled: Restore Stock",
      },
    ],
    orderPaymentTypes: [
      {
        key: "cash_on_delivery",
        value: "Cash On Delivery",
      },
      {
        key: "electronic",
        value: "Electronic",
      },
    ],
    productCategoryStatuses: [
      {
        key: "active",
        value: "Active",
      },
      {
        key: "inactive",
        value: "Inactive",
      },
    ],
    productStatuses: [
      {
        key: "active",
        value: "Active",
      },
      {
        key: "inactive",
        value: "Inactive",
      },
    ],
    questionStatuses: [
      {
        key: "active",
        value: "Active",
      },
      {
        key: "inactive",
        value: "Inactive",
      },
    ],
    surveyStatuses: [
      {
        key: "active",
        value: "Active",
      },
      {
        key: "inactive",
        value: "Inactive",
      },
    ],
    userStatuses: [
      {
        key: "active",
        value: "Active",
      },
      {
        key: "inactive",
        value: "Inactive",
      },
      {
        key: "pending_approval",
        value: "Pending Approval",
      },
    ],
    userTypes: [
      {
        key: "admin",
        value: "Admin",
      },
      {
        key: "agent",
        value: "Agent",
      },
      {
        key: "consumer",
        value: "Consumer",
      },
      {
        key: "driver",
        value: "Driver",
      },
      {
        key: "manufacturer_owner",
        value: "Manufacturer Owner",
      },
      {
        key: "shop_owner",
        value: "Shop Owner",
      },
      {
        key: "super_admin",
        value: "Super Admin",
      },
      {
        key: "warehouse_manager",
        value: "Warehouse Manager",
      },
      {
        key: "packer",
        value: "Packer",
      },
    ],
    weightMeasures: [
      {
        key: "Gram(s)",
        value: "g",
      },
      {
        key: "Kilogram(s)",
        value: "kg",
      },
      {
        key: "Milliliter(s)",
        value: "ml",
      },
      {
        key: "Liter(s)",
        value: "l",
      },
      {
        key: "Small",
        value: "sm",
      },
      {
        key: "Medium",
        value: "md",
      },
      {
        key: "Large",
        value: "lg",
      },
      {
        key: "Extra Large",
        value: "xl",
      },
      {
        key: "Jumbo",
        value: "jm",
      },
    ],
  }),
  computed: {},
  methods: {
    camalize(str) {
      return str
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    },

    async fetchStaticDataByGrouping(grouping) {
      const config = { ...this.sessionStore.getHttpConfig };
      config.headers["Accept"] = "application/json;";
      config.headers["Content-Type"] = "application/json;";
      return this.$http.get(`/static-data/grouping/${grouping}`, config);
    },

    async fetchUsersByRole(role) {
      const config = { ...this.sessionStore.getHttpConfig };
      config.headers["Accept"] = "application/json;";
      config.headers["Content-Type"] = "application/json;";
      return this.$http.post(
        "/users/search",
        [
          {
            field: "role",
            operator: "=",
            value: role,
          },
        ],
        config
      );
    },

    getDateTodayIso() {
      const today = new Date();
      return today.toISOString();
    },

    getMinDeliveryDate() {
      const today = new Date();

      if (today.getHours() < 13) {
        today.setDate(today.getDate() + 1);
        return today.toISOString();
      }

      today.setDate(today.getDate() + 2);
      return today.toISOString();
    },

    getMaxDeliveryDate() {
      const today = new Date();

      if (today.getHours() < 13) {
        today.setDate(today.getDate() + 8);
        return today.toISOString();
      }

      today.setDate(today.getDate() + 9);
      return today.toISOString();
    },

    getNotePrefix(note) {
      const date = new Date(note.created_at);
      return `${date.toLocaleDateString()} - ${note.user.firstname} 
        ${note.user.lastname}`;
    },

    handleHttpError(
      error,
      fallbackErrorMessage = "Something went wrong. Please try again or contact support."
    ) {
      if (
        error &&
        error.response &&
        error.response.status === 422 &&
        ((error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.length > 0) ||
          (error.response.data && error.response.data.message))
      ) {
        if (error.response.data.errors) {
          if (Array.isArray(error.response.data.errors)) {
            this.$notify.error(
              `Validation error: ${error.response.data.errors[0]}`
            );
          } else {
            const keys = Object.keys(error.response.data.errors);
            this.$notify.error(
              `Validation error: ${error.response.data.errors[keys[0]]}`
            );
          }
        } else {
          this.$notify.error(
            `Validation error: ${error.response.data.message}`
          );
        }
      } else if (error && error.response && error.response.status === 403) {
        this.$notify.error(
          "You do not have permission to perform this action. Please contact your administrator."
        );
      } else {
        this.$notify.error(fallbackErrorMessage);
      }
      console.log(error);
    },

    setAddressDetailsFromGooglePlacesApi(
      addressDetails,
      placesApiAddressData,
      placesApiPlaceResultData
    ) {
      addressDetails.latitude = placesApiAddressData.latitude;
      addressDetails.longitude = placesApiAddressData.longitude;

      addressDetails.surburb = placesApiAddressData.vicinity;
      addressDetails.city = placesApiAddressData.locality;

      for (
        let i = 0;
        i < placesApiPlaceResultData.address_components.length;
        i += 1
      ) {
        const component = placesApiPlaceResultData.address_components[i];

        if (component.types.includes("sublocality_level_1")) {
          addressDetails.surburb = component.long_name;
        }

        if (component.types.includes("locality")) {
          addressDetails.city = component.long_name;
        }

        if (component.types.includes("administrative_area_level_1")) {
          addressDetails.province = component.long_name;
        }

        if (component.types.includes("postal_code")) {
          addressDetails.code = component.long_name;
        }
      }

      addressDetails.addressLine1 = placesApiPlaceResultData.formatted_address;
      addressDetails.addressLine2 = placesApiPlaceResultData.formatted_address;
      addressDetails.addressLine3 = placesApiPlaceResultData.formatted_address;
    },

    setPaginationDetails(apiResponse) {
      this.paginationDetails.page = apiResponse.current_page;
      this.paginationDetails.pageCount = apiResponse.last_page;
      this.paginationDetails.itemsPerPage = apiResponse.per_page;
      this.paginationDetails.pageStart = apiResponse.from;
      this.paginationDetails.pageStop = apiResponse.last_page;
      this.paginationDetails.itemsLength = apiResponse.total;
      this.paginationDetails.firstPageUrl = apiResponse.first_page_url;
      this.paginationDetails.lastPageUrl = apiResponse.last_page_url;
      this.paginationDetails.nextPageUrl = apiResponse.next_page_url;
      this.paginationDetails.previousPageUrl = apiResponse.prev_page_url;
      this.paginationDetails.path = apiResponse.path;

      this.paginationDetails.links.splice(0);
      for (let i = 0; i < apiResponse.links.length; i += 1) {
        this.paginationDetails.links.push(apiResponse.links[i]);
      }
    },

    titleCase(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(" ");
    },

    updateSelectedPage(newValue) {
      const nextUrl = `${this.paginationDetails.path}?page=${newValue}&size=${this.paginationDetails.itemsPerPage}`;
      this.fetchDataByUrl(nextUrl);

      // const link = this.paginationDetails.links.find((obj) => {
      //   return Number(obj.label) === newValue;
      // });

      // if (link) {
      //   this.fetchDataByUrl(link.url);
      // } else {
      //   this.$notify.error("The selected page is invalid.");
      // }
    },
  },
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
  store,
}).$mount("#app");
