import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import AuthLayout from "@/views/Layout/AuthLayout";
import notify from "@/util/notify";
import pinia from "@/store";
import { useSessionStore } from "@/store/session";

const sessionStore = useSessionStore(pinia);

const Dashboard = () => import("../views/pages/Dashboard.vue");
const Login = () => import("@/views/pages/Login");
const ForgotPassword = () => import("@/views/pages/ForgotPassword");

// Vuleka Pages
const Banners = () => import("../views/pages/Banners.vue");
const BannersEdit = () => import("../views/pages/BannersEdit.vue");
const BannersCreate = () => import("../views/pages/BannersCreate.vue");
const Deliveries = () => import("../views/pages/Deliveries.vue");
const DeliveriesEdit = () => import("../views/pages/DeliveriesEdit.vue");
const Drivers = () => import("../views/pages/Drivers.vue");
const DriversEdit = () => import("../views/pages/DriversEdit.vue");
const Manufacturers = () => import("../views/pages/Manufacturers.vue");
const ManufacturersCreate = () => import("@/views/pages/ManufacturersCreate");
const ManufacturersEdit = () => import("@/views/pages/ManufacturersEdit");
const Orders = () => import("@/views/pages/Orders");
const OrdersEdit = () => import("@/views/pages/OrdersEdit");
const OrdersCreate = () => import("@/views/pages/OrdersCreate");
const Products = () => import("../views/pages/Products.vue");
const ProductsEdit = () => import("../views/pages/ProductsEdit.vue");
const ProductsCreate = () => import("../views/pages/ProductsCreate.vue");
const ProductCategories = () => import("../views/pages/ProductCategories.vue");
const ProductCategoriesEdit = () =>
  import("../views/pages/ProductCategoriesEdit.vue");
const ProductCategoriesCreate = () =>
  import("../views/pages/ProductCategoriesCreate.vue");
const ProductSubCategories = () =>
  import("../views/pages/ProductSubCategories.vue");
const ProductSubCategoriesEdit = () =>
  import("../views/pages/ProductSubCategoriesEdit.vue");
const ProductSubCategoriesCreate = () =>
  import("../views/pages/ProductSubCategoriesCreate.vue");
const ProductGroups = () => import("../views/pages/ProductGroups.vue");
const ProductGroupsEdit = () => import("../views/pages/ProductGroupsEdit.vue");
const ProductGroupsCreate = () =>
  import("../views/pages/ProductGroupsCreate.vue");
const Settings = () => import("../views/pages/Settings.vue");
const SettingsCreate = () => import("../views/pages/SettingsCreate.vue");
const SettingsEdit = () => import("../views/pages/SettingsEdit.vue");
const Shops = () => import("../views/pages/Shops.vue");
const ShopsCreate = () => import("../views/pages/ShopsCreate.vue");
const ShopsEdit = () => import("../views/pages/ShopsEdit.vue");
const Surveys = () => import("../views/pages/Surveys.vue");
const SurveysCreate = () => import("@/views/pages/SurveysCreate");
const SurveysEdit = () => import("../views/pages/SurveysEdit.vue");
const SurveyQuestionsCreate = () =>
  import("../views/pages/SurveyQuestionsCreate.vue");
const SurveyQuestionsEdit = () =>
  import("../views/pages/SurveyQuestionsEdit.vue");
const Users = () => import("../views/pages/Users.vue");
const UsersEdit = () => import("../views/pages/UsersEdit.vue");
const UsersCreate = () => import("../views/pages/UsersCreate.vue");
const Vouchers = () => import("../views/pages/Vouchers.vue");
const VouchersCreate = () => import("@/views/pages/VouchersCreate");

// const Dashboard = () => import("../views/Dashboard.vue");

// // Dashboard pages
// const Dashboard = () => import("../views/Dashboard/Dashboard.vue");
// const AlternativeDashboard = () =>
//   import("../views/Dashboard/AlternativeDashboard.vue");
// const Calendar = () => import("../views/Dashboard/Calendar.vue");
// const Charts = () => import("../views/Dashboard/Charts.vue");
// const Widgets = () => import("../views/Dashboard/Widgets.vue");

// // Components pages
// const Buttons = () => import("../views/Components/Buttons.vue");
// const Grid = () => import("../views/Components/Grid.vue");
// const Icons = () => import("../views/Components/Icons.vue");
// const Typography = () => import("../views/Components/Typography.vue");
// const Cards = () => import("../views/Components/Cards.vue");
// const Notifications = () => import("../views/Components/Notifications.vue");

// // Maps pages
// const GoogleMaps = () => import("../views/Maps/Google.vue");
// const VectorMaps = () => import("../views/Maps/Vector.vue");

// // Tables pages
// const RegularTables = () => import("../views/Tables/RegularTables.vue");
// const SortableTables = () => import("../views/Tables/SortableTables.vue");
// const PaginatedTables = () => import("../views/Tables/PaginatedTables.vue");

// // Forms pages
// const FormElements = () => import("../views/Forms/FormElements.vue");
// const FormComponents = () => import("../views/Forms/FormComponents.vue");
// const FormValidation = () => import("../views/Forms/FormValidation.vue");

// // Pages
// const Pricing = () =>
//   import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
// const Login = () =>
//   import(/* webpackChunkName: "pages" */ "@/views/Pages/Login.vue");
// const Register = () =>
//   import(/* webpackChunkName: "pages" */ "@/views/Pages/Register.vue");
// const Lock = () =>
//   import(/* webpackChunkName: "pages" */ "@/views/Pages/Lock.vue");
// const Profile = () =>
//   import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile.vue");
// const Timeline = () =>
//   import(/* webpackChunkName: "pages" */ "@/views/Pages/Timeline.vue");

Vue.use(VueRouter);

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    // {
    //   path: "buttons",
    //   name: "Buttons",
    //   components: { default: Buttons },
    //   meta: {
    //     groupName: "Components",
    //   },
    // },
    // {
    //   path: "cards",
    //   name: "Cards",
    //   components: { default: Cards },
    //   meta: {
    //     groupName: "Components",
    //   },
    // },
    // {
    //   path: "grid",
    //   name: "Grid",
    //   components: { default: Grid },
    //   meta: {
    //     groupName: "Components",
    //   },
    // },
    // {
    //   path: "notifications",
    //   name: "Notifications",
    //   components: { default: Notifications },
    //   meta: {
    //     groupName: "Components",
    //   },
    // },
    // {
    //   path: "icons",
    //   name: "Icons",
    //   components: { default: Icons },
    //   meta: {
    //     groupName: "Components",
    //   },
    // },
    // {
    //   path: "typography",
    //   name: "Typography",
    //   components: { default: Typography },
    //   meta: {
    //     groupName: "Components",
    //   },
    // },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  meta: {
    requiresAuth: false,
    hasToolbar: false,
  },
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/forgot-password",
      name: "ForgotPassword",
      component: ForgotPassword,
    },
    // {
    //   path: "/register",
    //   name: "Register",
    //   component: Register,
    // },
    // {
    //   path: "/pricing",
    //   name: "Pricing",
    //   component: Pricing,
    // },
    // {
    //   path: "/lock",
    //   name: "Lock",
    //   component: Lock,
    // },
  ],
};

// let mapsMenu = {
//   path: "/maps",
//   component: DashboardLayout,
//   redirect: "/maps/google",
//   name: "Maps",
//   children: [
//     {
//       path: "/maps/google",
//       name: "Google",
//       components: { default: GoogleMaps },
//       meta: {
//         groupName: "Maps",
//       },
//     },
//     {
//       path: "/maps/vector",
//       name: "Vector",
//       components: { default: VectorMaps },
//       meta: {
//         groupName: "Maps",
//       },
//     },
//   ],
// };

// let formsMenu = {
//   path: "/forms",
//   component: DashboardLayout,
//   redirect: "/forms/elements",
//   name: "Forms",
//   children: [
//     {
//       path: "elements",
//       name: "Elements",
//       components: { default: FormElements },
//       meta: {
//         groupName: "Forms",
//       },
//     },
//     {
//       path: "components",
//       name: "Components",
//       components: { default: FormComponents },
//       meta: {
//         groupName: "Forms",
//       },
//     },
//     {
//       path: "validation",
//       name: "Validation",
//       components: { default: FormValidation },
//       meta: {
//         groupName: "Forms",
//       },
//     },
//   ],
// };

// let tablesMenu = {
//   path: "/tables",
//   component: DashboardLayout,
//   redirect: "/tables/regular",
//   name: "Tables menu",
//   children: [
//     {
//       path: "regular",
//       name: "Regular",
//       components: { default: RegularTables },
//       meta: {
//         groupName: "Tables",
//       },
//     },
//     {
//       path: "sortable",
//       name: "Sortable",
//       components: { default: SortableTables },
//       meta: {
//         groupName: "Tables",
//       },
//     },
//     {
//       path: "paginated",
//       name: "Paginated",
//       components: { default: PaginatedTables },
//       meta: {
//         groupName: "Tables",
//       },
//     },
//   ],
// };

const routes = [
  {
    path: "/",
    name: "Dashboard Layout",
    redirect: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          groupName: "Dashboards",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager"],
        },
      },
      {
        path: "banners",
        name: "Banner",
        component: Banners,
        meta: {
          groupName: "Banners",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin"],
        },
      },
      {
        path: "banners/edit/:id",
        name: "Edit Banner",
        component: BannersEdit,
        meta: {
          groupName: "Banners",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin"],
        },
        props: true,
      },
      {
        path: "banners/create",
        name: "Create Banner",
        component: BannersCreate,
        meta: {
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin"],
        },
      },
      {
        path: "deliveries",
        name: "Deliveries",
        component: Deliveries,
        meta: {
          groupName: "Deliveries",
          requiresAuth: true,
          hasToolbar: true,
          roles: [
            "super_admin",
            "admin",
            "agent",
            "warehouse_manager",
            "driver",
            "packer",
          ],
        },
      },
      {
        path: "deliveries/edit/:id",
        name: "Edit Delivery",
        component: DeliveriesEdit,
        meta: {
          groupName: "Deliveries",
          requiresAuth: true,
          hasToolbar: true,
          roles: [
            "super_admin",
            "admin",
            "agent",
            "warehouse_manager",
            "driver",
            "packer",
          ],
        },
        props: true,
      },
      {
        path: "drivers",
        name: "Drivers",
        component: Drivers,
        meta: {
          groupName: "Drivers",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager", "packer"],
        },
      },
      {
        path: "drivers/edit/:id",
        name: "Edit Driver",
        component: DriversEdit,
        meta: {
          groupName: "Drivers",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager", "packer"],
        },
        props: true,
      },
      {
        path: "manufacturers",
        name: "Manufacturers",
        component: Manufacturers,
        meta: {
          groupName: "Manufacturers",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager"],
        },
      },
      {
        path: "manufacturers/create",
        name: "Create Manufacturer",
        component: ManufacturersCreate,
        meta: {
          groupName: "Manufacturers",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager"],
        },
      },
      {
        path: "manufacturers/edit/:id",
        name: "Edit Manufacturer",
        component: ManufacturersEdit,
        meta: {
          groupName: "Manufacturers",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager"],
        },
      },
      {
        path: "orders",
        name: "Orders",
        component: Orders,
        meta: {
          groupName: "Orders",
          requiresAuth: true,
          hasToolbar: true,
          roles: [
            "super_admin",
            "admin",
            "agent",
            "warehouse_manager",
            "packer",
          ],
        },
      },
      {
        path: "orders/edit/:id",
        name: "Edit Order",
        component: OrdersEdit,
        meta: {
          groupName: "Orders",
          requiresAuth: true,
          hasToolbar: true,
          roles: [
            "super_admin",
            "admin",
            "agent",
            "warehouse_manager",
            "packer",
          ],
        },
        props: true,
      },
      {
        path: "orders/create",
        name: "Create Order",
        component: OrdersCreate,
        meta: {
          groupName: "Orders",
          requiresAuth: true,
          hasToolbar: true,
          roles: [
            "super_admin",
            "admin",
            "agent",
            "warehouse_manager",
            "packer",
          ],
        },
      },
      {
        path: "products",
        name: "Products",
        component: Products,
        meta: {
          groupName: "Products",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager"],
        },
      },
      {
        path: "products/edit/:id",
        name: "Edit Product",
        component: ProductsEdit,
        meta: {
          groupName: "Products",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager"],
        },
        props: true,
      },
      {
        path: "products/create",
        name: "Create Product",
        component: ProductsCreate,
        meta: {
          groupName: "Products",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager"],
        },
        props: true,
      },
      {
        path: "product-categories",
        name: "Product Categories",
        component: ProductCategories,
        meta: {
          groupName: "Product Categories",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager"],
        },
      },
      {
        path: "product-categories/edit/:id",
        name: "Edit Product Category",
        component: ProductCategoriesEdit,
        meta: {
          groupName: "Product Categories",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager"],
        },
        props: true,
      },
      {
        path: "product-categories/create",
        name: "Create Product Category",
        component: ProductCategoriesCreate,
        meta: {
          groupName: "Product Categories",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager"],
        },
        props: true,
      },
      {
        path: "product-sub-categories",
        name: "Product Sub Categories",
        component: ProductSubCategories,
        meta: {
          groupName: "Product Sub Categories",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager"],
        },
      },
      {
        path: "product-sub-categories/edit/:id",
        name: "Edit Product Sub Category",
        component: ProductSubCategoriesEdit,
        meta: {
          groupName: "Product Sub Categories",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager"],
        },
        props: true,
      },
      {
        path: "product-sub-categories/create",
        name: "Create Product Sub Category",
        component: ProductSubCategoriesCreate,
        meta: {
          groupName: "Product Sub Categories",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager"],
        },
        props: true,
      },
      {
        path: "product-groups",
        name: "Product Groups",
        component: ProductGroups,
        meta: {
          groupName: "Product Groups",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager"],
        },
      },
      {
        path: "product-groups/edit/:id",
        name: "Edit Product Groups",
        component: ProductGroupsEdit,
        meta: {
          groupName: "Product Groups",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager"],
        },
        props: true,
      },
      {
        path: "product-groups/create",
        name: "Create Product Group",
        component: ProductGroupsCreate,
        meta: {
          groupName: "Product Groups",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager"],
        },
        props: true,
      },
      {
        path: "surveys",
        name: "Surveys",
        component: Surveys,
        meta: {
          groupName: "Surveys",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin"],
        },
      },
      {
        path: "surveys/create",
        name: "Create Survey",
        component: SurveysCreate,
        meta: {
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin"],
        },
      },
      {
        path: "surveys/edit/:id",
        name: "Edit Survey",
        component: SurveysEdit,
        meta: {
          groupName: "Surveys",
          requiresAuth: true,
          roles: ["super_admin", "admin"],
        },
        props: true,
      },
      {
        path: "surveys/:surveyId/questions",
        name: "Create Survey Question",
        component: SurveyQuestionsCreate,
        meta: {
          groupName: "Surveys",
          hasToolbar: true,
          roles: ["super_admin", "admin"],
        },
        props: true,
      },
      {
        path: "surveys/:surveyId/questions/edit/:questionId",
        name: "Edit Survey Question",
        component: SurveyQuestionsEdit,
        meta: {
          groupName: "Surveys",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin"],
        },
        props: true,
      },
      {
        path: "shops",
        name: "Shops",
        component: Shops,
        meta: {
          groupName: "Shops",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "agent", "warehouse_manager"],
        },
      },
      {
        path: "shops/create",
        name: "Create Shop",
        component: ShopsCreate,
        meta: {
          groupName: "Shops",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "agent", "warehouse_manager"],
        },
      },
      {
        path: "shops/edit/:id",
        name: "Edit Shop",
        component: ShopsEdit,
        meta: {
          groupName: "Shops",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "agent", "warehouse_manager"],
        },
        props: true,
      },
      {
        path: "settings",
        name: "Settings",
        component: Settings,
        meta: {
          groupName: "Settings",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin"],
        },
      },
      {
        path: "settings/:type/create",
        name: "Create Settings",
        component: SettingsCreate,
        meta: {
          groupName: "Settings",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin"],
        },
        props: true,
      },
      {
        path: "settings/:type/edit/:id",
        name: "Edit Settings",
        component: SettingsEdit,
        meta: {
          groupName: "Settings",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin"],
        },
        props: true,
      },
      {
        path: "users",
        name: "Users",
        component: Users,
        meta: {
          groupName: "Users",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager", "agent"],
        },
      },
      {
        path: "users/edit/:id",
        name: "Edit User",
        component: UsersEdit,
        meta: {
          groupName: "Users",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager", "agent"],
        },
        props: true,
      },
      {
        path: "users/create",
        name: "Create User",
        component: UsersCreate,
        meta: {
          groupName: "Users",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin", "warehouse_manager", "agent"],
        },
      },
      {
        path: "vouchers",
        name: "Vouchers",
        component: Vouchers,
        meta: {
          groupName: "Vouchers",
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin"],
        },
      },
      {
        path: "vouchers/create",
        name: "Create Voucher",
        component: VouchersCreate,
        meta: {
          requiresAuth: true,
          hasToolbar: true,
          roles: ["super_admin", "admin"],
        },
      },
    ],
  },
  authPages,
  componentsMenu,
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export { router as default };

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = sessionStore.getToken;

    if (!token || token === "") {
      notify.error("Your Session has expired, please login.");
      router.push({ path: "/login" });
    } else {
      const userRoles = sessionStore.getUserRoles;
      const hasPermission = to.meta.roles.filter((value) =>
        userRoles.includes(value)
      );

      if (hasPermission.length > 0) {
        next();
      } else {
        // notify.warning(`You do not have permissions to access ${to.name}.`);
        // Route the user to a page they have access to...
        if (
          ["agent", "warehouse_manager", "packer"].filter((role) =>
            userRoles.includes(role)
          ).length > 0
        ) {
          router.push({ path: "/orders" });
        } else if (
          ["driver"].filter((role) => userRoles.includes(role)).length > 0
        ) {
          router.push({ path: "/deliveries" });
        } else {
          // destroy the login session...
          notify.error(
            "You do not have permissions to access this portal. Please contact your administrator if this is in error."
          );

          sessionStore.unsetSession();
          if (from.fullPath !== "/login") {
            router.push({ path: "/login" });
          }
        }
      }
    }
  } else {
    next();
  }
});
